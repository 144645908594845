import React, { useState } from 'react';
import { httpClient } from '../../httpClient';
import UserResults from '../user-results/user-results';
import { Combobox, TextField, Button, Tooltip } from '@watts/design-system-react';
import { useUser } from '../../user-context';
import { Environments, EnvironmentOptions } from '../../types/environments';
import { Opcos, OpcoOptions } from '../../types/opcos';
import { Areas, AreaOptions } from '../../types/areas';
import { Regions, RegionOptions } from '../../types/regions';
import { TeamOptions } from '../../types/teams';
import { UserTypeOptions } from '../../types/user-types';
import { useAppState } from '../../app-state-context';
import { useAuth } from '../../auth-provider';
export default function Search() {
    const { accessToken, refreshToken } = useAuth();
    const { requestedBy } = useUser();
    const { appState, setAppState } = useAppState();
    const [areaSelected, setAreaSelected] = useState(appState.query.area);
    const [regionSelected, setRegionSelected] = useState(appState.query.region);
    const [envSelected, setEnvSelected] = useState(appState.query.env);
    const [opcoSelected, setOpcoSelected] = useState(appState.query.opco);
    const [userTypeSelected, setUserTypeSelected] = useState(appState.query.userType);
    const [label, setLabel] = useState(appState.query.label);
    const [accountId, setAccountId] = useState(appState.query.accountId);
    const [ownerTeam, setOwnerTeam] = useState(appState.query.ownerTeam);
    const [email, setEmail] = useState(appState.query.email);
    const [description, setDescription] = useState(appState.query.description);
    const [results, setResults] = useState([]);
    React.useEffect(() => {
        setAppState(prevState => {
            return {
                ...prevState,
                previousPage: prevState.currentPage,
                currentPage: '/user-search',
                query: {
                    area: areaSelected,
                    region: regionSelected,
                    env: envSelected,
                    opco: opcoSelected,
                    label,
                    accountId,
                    ownerTeam,
                    email,
                    description,
                    userType: userTypeSelected,
                },
            };
        });
        handleValueChange('area', areaSelected);
        handleValueChange('region', regionSelected);
        handleValueChange('env', envSelected);
        handleValueChange('opco', opcoSelected);
        handleValueChange('userType', userTypeSelected);
        console.log(appState.query);
    }, [areaSelected, regionSelected, envSelected, opcoSelected, label, accountId, ownerTeam, email, description, userTypeSelected]);
    const handleValueChange = (name, value) => {
        switch (name) {
            case 'area':
                setEnvSelected(EnvironmentOptions[0].value);
                handleValueChange('region', RegionOptions[0].value);
                setOpcoSelected(OpcoOptions[0].value);
                setAreaSelected(value);
                break;
            case 'region':
                setRegionSelected(value);
                break;
            case 'env':
                setEnvSelected(value);
                break;
            case 'opco':
                setOpcoSelected(value);
                break;
            case 'userType':
                setUserTypeSelected(value);
                break;
        }
    };
    async function search() {
        await refreshToken();
        const params = {
            requestedBy,
            area: areaSelected === Areas[0] ? '' : areaSelected,
            region: regionSelected === Regions[0] ? '' : regionSelected,
            env: envSelected === Environments[0] ? '' : envSelected,
            opco: opcoSelected === Opcos[0] ? '' : opcoSelected,
            encodedLabel: encodeURIComponent(label),
            encodedAccountId: encodeURIComponent(accountId),
            encodedOwnerTeam: encodeURIComponent(ownerTeam),
            encodedEmail: encodeURIComponent(email),
            encodedDescription: encodeURIComponent(description),
            userType: userTypeSelected,
            source: 'frontend',
            token: accessToken
        };
        const response = await httpClient.getUsers(params);
        setResults(response.data);
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        await search();
    };
    const styles = {
        container: {
            width: 'auto',
        },
        comboboxContainer: {
            height: '100%',
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 1fr)',
            gridGap: '1em',
            gap: '10px',
            paddingBottom: '20px',
        },
        inputContainer: {
            width: 'auto',
            height: '100%',
            display: 'grid',
            gridTemplateColumns: 'repeat(6, 1fr)',
            gridGap: '1em',
            gap: '1em',
            paddingBottom: '20px',
            alignItems: 'flex-end',
        },
    };
    return (React.createElement("div", { "data-testid": 'search' },
        React.createElement("form", { onSubmit: handleSubmit },
            React.createElement("div", { style: styles.container },
                React.createElement("div", { style: styles.comboboxContainer },
                    React.createElement(Combobox, { label: 'Select the area', options: AreaOptions, defaultSelectedOption: { label: appState.query.area, value: appState.query.area }, onValueChange: (value) => handleValueChange('area', value), "data-testid": 'area-select', noMatchLabel: '' }),
                    areaSelected === 'AX' &&
                        React.createElement(Combobox, { label: 'Select the region', options: RegionOptions, defaultSelectedOption: { label: appState.query.region, value: appState.query.region }, onValueChange: (value) => handleValueChange('region', value), "data-testid": 'region-select', noMatchLabel: '' }),
                    areaSelected !== Areas[0] &&
                        React.createElement(Combobox, { label: 'Select the environment', options: EnvironmentOptions, defaultSelectedOption: { label: appState.query.env, value: appState.query.env }, onValueChange: (value) => handleValueChange('env', value), "data-testid": 'env-select', noMatchLabel: '' }),
                    areaSelected === 'CX' &&
                        React.createElement(Tooltip, { label: 'Input is editable. Delete the value to select another option.', placement: 'top-right' },
                            React.createElement(Combobox, { label: 'Select a OpCo', options: OpcoOptions, defaultSelectedOption: { label: appState.query.opco, value: appState.query.opco }, onValueChange: (value) => handleValueChange('opco', value), "data-testid": 'opco-select', autocomplete: true, noMatchLabel: '' })),
                    React.createElement(Combobox, { label: 'User Type', name: 'userType', options: UserTypeOptions, defaultSelectedOption: { label: appState.query.userType, value: appState.query.userType }, onValueChange: (value) => handleValueChange('userType', value), "data-testid": 'user-type', noMatchLabel: '' }))),
            React.createElement("div", { style: styles.inputContainer },
                React.createElement(TextField, { label: 'Label', name: 'label', textFieldSize: 's', type: 'text', value: appState.query.label, "data-testid": 'label', onChange: (e) => setLabel(e.target.value) }),
                React.createElement(TextField, { label: 'Email', name: 'email', textFieldSize: 's', type: 'text', value: appState.query.email, "data-testid": 'email', onChange: (e) => setEmail(e.target.value) }),
                React.createElement(TextField, { label: 'Account Id', name: 'account-id', textFieldSize: 's', type: 'text', value: appState.query.accountId, "data-testid": 'accountId', onChange: (e) => setAccountId(e.target.value) }),
                React.createElement(Combobox, { label: 'Owner team', name: 'ownerTeam', options: TeamOptions, defaultSelectedOption: appState.query.ownerTeam, onValueChange: (value) => setOwnerTeam(value || ''), "data-testid": 'ownerTeam', noMatchLabel: '' }),
                React.createElement(TextField, { label: 'Description', name: 'description', textFieldSize: 's', type: 'text', value: appState.query.description, "data-testid": 'description', onChange: (e) => setDescription(e.target.value) }),
                React.createElement(Button, { variant: 'primary', type: 'submit', "data-testid": 'searchButton' }, "Search"))),
        results.length > 0 && React.createElement(UserResults, { data: results })));
}

export const CxLinks = new Map([
    [
        'Preprod',
        new Map([
            ['AUTH_POC', 'https://fra-vanilla-preprod.dev.spark.sonepar.com'],
            ['AUT-ROUTECO', 'https://shop-preprod.at.routeco.com'],
            ['BEL-CEBEO', 'https://shop-preprod.at.routeco.com'],
            ['BEL-ROUTECO', 'https://shop-preprod.be.routeco.com'],
            ['FIN-SONFIN', 'https://ppshop.sonepar.fi'],
            ['FRA-CONNECT', 'https://spark-ppr.sonepar.fr'],
            ['GBR-ROUTECO', 'https://shop-preprod.uk.routeco.com'],
            ['HUN-SONHUN', 'https://shop-preprod.sonepar.hu'],
            ['ITA-ELETTRO', 'https://preprod.new.elettroveneta.it'],
            ['ITA-HDUEA', 'https://spark-preprod.hduea.it'],
            ['ITA-SACCHI', 'https://preprod.sacchi.it'],
            ['ITA-SONITA', 'https://spark-preprod.sonepar.it'],
            ['NLD-ROUTECO', 'https://shop-preprod.nl.routeco.com'],
            ['NLD-TECUNIE', 'https://preprod.new.technischeunie.nl'],
            ['NOR-SONNOR', 'https://preprod.sonepar.no'],
            ['USA-CRAWFRD', 'https://beta-ppr.crawfordelectricsupply.com'],
            ['USA-SPRFLD', 'https://beta-ppr.springfieldelectric.com'],
        ]),
    ],
    [
        'Prod',
        new Map([
            ['AUTH_POC', 'https://fra-vanilla-prod.spark.sonepar.com/'],
            ['AUT-ROUTECO', 'https://shop.at.routeco.com'],
            ['BEL-CEBEO', 'https://www.cebeo.be'],
            ['BEL-ROUTECO', 'https://shop.be.routeco.com'],
            ['FIN-SONFIN', 'https://shop.sonepar.fi'],
            ['FRA-CONNECT', 'https://www.sonepar.fr'],
            ['GBR-ROUTECO', 'https://shop.uk.routeco.com'],
            ['HUN-SONHUN', 'https://shop.sonepar.hu'],
            ['ITA-ELETTRO', 'https://sparklogin.new.elettroveneta.it'],
            ['ITA-HDUEA', 'https://spark.hduea.it'],
            ['ITA-SACCHI', 'https://new.sacchi.it'],
            ['ITA-SONITA', 'https://www.sonepar.it'],
            ['NLD-ROUTECO', 'https://shop.nl.routeco.com'],
            ['NLD-TECUNIE', 'https://new.technischeunie.nl'],
            ['NOR-SONNOR', 'https://www.sonepar.no'],
            ['USA-CRAWFRD', 'https://sparklogin.crawfordelectricsupply.com'],
            ['USA-SPRFLD', 'https://www.springfieldelectric.com'],
        ]),
    ],
    [
        'Sint',
        new Map([
            ['AUTH_POC', 'https://fra-vanilla-sint.dev.spark.sonepar.com/'],
            ['ITA-SACCHI', 'https://ita-sacchi-sint.dev.spark.sonepar.com/it-it'],
        ]),
    ]
]);
export const AxLinks = new Map([
    [
        'Canary',
        new Map([
            ['Common', 'https://soneparglobaleucanary.crm4.dynamics.com'],
        ]),
    ],
    [
        'Feat',
        new Map([
            ['EU', 'https://soneparglobaleufeat.crm4.dynamics.com'],
            ['NA', 'https://soneparglobaleusint.crm4.dynamics.com'],
        ]),
    ],
    [
        'Preprod',
        new Map([
            ['EU', 'https://soneparglobalpreprod.crm4.dynamics.com'],
            ['NA', 'https://soneparglobalna1preprod.crm.dynamics.com'],
        ]),
    ],
    [
        'Sint',
        new Map([
            ['EU', 'https://soneparglobaleusint.crm4.dynamics.com'],
            ['NA', 'https://soneparglobalna1sint.crm.dynamics.com'],
        ]),
    ],
]);

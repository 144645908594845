import React, { useEffect, useState } from 'react';
import { PageTypes } from '../../types/page-types';
import { Button, Tooltip } from '@watts/design-system-react';
export default function Pages({ onChangeTab }) {
    const [selectedTab, setSelectedTab] = useState(PageTypes['SEARCH']);
    useEffect(() => {
        onChangeTab(selectedTab);
    }, [selectedTab]);
    const styles = {
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            paddingTop: '20px',
        },
        tabs: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            maxWidth: '600px',
            margin: '1em',
            height: '50px',
        },
        tabContainer: {
            display: 'flex',
            justifyContent: 'center',
            flexGrow: 1,
        },
        tab: {
            padding: '0.5em',
            margin: '0.5em',
            cursor: 'pointer',
            borderRadius: '5px',
            border: '1px solid #ccc',
            display: 'flex',
            justifyContent: 'center',
        },
        selectedTab: {
            padding: '0.5em',
            margin: '0.5em',
            cursor: 'pointer',
            borderRadius: '5px',
            border: '1px solid #ccc',
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: '#ccc',
        },
        backButton: {
            marginRight: 'auto',
        }
    };
    return (React.createElement("div", { style: styles.container },
        React.createElement("div", { style: styles.tabs, "data-testid": 'pages' },
            React.createElement("div", { style: styles.tabContainer },
                React.createElement("div", { "data-testid": 'search', style: selectedTab === PageTypes.SEARCH ? styles.selectedTab : styles.tab, onClick: () => setSelectedTab(PageTypes.SEARCH) }, "User search"),
                React.createElement("div", { "data-testid": 'addUser', style: selectedTab === PageTypes.ADD_USER ? styles.selectedTab : styles.tab, onClick: () => setSelectedTab(PageTypes.ADD_USER) }, "Add user"),
                React.createElement("div", { "data-testid": 'links', style: selectedTab === PageTypes.LINKS ? styles.selectedTab : styles.tab, onClick: () => setSelectedTab(PageTypes.LINKS) }, "Links")),
            React.createElement(Tooltip, { placement: 'bottom', label: `This app does not create, edit or delete users in other Digital Factory apps. It serves as a centralized repository of test data, using it's own database. Users still need to be created in other apps. After that, they can be added here to be used in test frameworks that are connected to the API, or to be easily accessed by other Digital Factory members. Proudly maintained by the Core Automation Team.` },
                React.createElement(Button, { icon: 'info-line', variant: 'ghost' }, "Readme!")))));
}

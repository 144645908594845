import axios from 'axios';
export class httpClient {
    static token;
    static apiUrl = process.env.NODE_ENV === 'development'
        ? `http://localhost:${process.env.PORT}/qa-api`
        : `https://qa-ms.spark.sonepar.com/qa-api`;
    static expiresAt;
    static async getToken() {
        const now = new Date().getTime();
        let expiresIn;
        try {
            const response = await axios.post(`${this.apiUrl}/auth`, {
                username: process.env.API_USER_USERNAME,
                pwd: process.env.API_USER_PWD,
            });
            if (response && response.data && response.data.token) {
                this.token = response.data.token;
                expiresIn = 3600000; // 1 hour
                this.expiresAt = now + expiresIn;
            }
            else {
                throw new Error('Invalid response from authentication endpoint');
            }
        }
        catch (err) {
            console.error('Failed to get token:', err);
            throw new Error('Failed to get token');
        }
    }
    static async getUsersWithMfa(requestedBy, source, token) {
        if (!this.token || this.expiresAt < new Date().getTime()) {
            await this.getToken();
        }
        return axios.get(`${this.apiUrl}/v2/users`, {
            headers: {
                'Authorization': `Bearer ${token || this.token}`,
                'X-Requested-By': requestedBy,
                'X-Source': source
            },
            params: {
                tapEnabled: true
            }
        });
    }
    static async getUsers(params) {
        const { requestedBy, area, region, env, opco, encodedLabel, encodedAccountId, encodedOwnerTeam, encodedEmail, encodedDescription, userType, source, token } = params;
        if (!token && (!this.token || this.expiresAt < new Date().getTime())) {
            await this.getToken();
        }
        return axios.get(`${this.apiUrl}/v2/users`, {
            headers: {
                'Authorization': `Bearer ${token || this.token}`,
                'X-Requested-By': requestedBy,
                'X-Source': source
            },
            params: {
                envName: env,
                opco: opco,
                label: encodedLabel,
                accountId: encodedAccountId,
                ownerTeam: encodedOwnerTeam,
                email: encodedEmail,
                description: encodedDescription || '',
                area: area,
                region: region,
                type: userType
            }
        });
    }
    static async createUser(requestedBy, userInfo, source, token) {
        if (!token && (!this.token || this.expiresAt < new Date().getTime())) {
            await this.getToken();
        }
        return axios.post(`${this.apiUrl}/v2/users`, userInfo, {
            headers: {
                'Authorization': `Bearer ${token || this.token}`,
                'X-Requested-By': requestedBy,
                'X-Source': source
            }
        });
    }
    static async updateUser(requestedBy, userInfo, source, token) {
        if (!token && (!this.token || this.expiresAt < new Date().getTime())) {
            await this.getToken();
        }
        return axios.put(`${this.apiUrl}/v2/users`, userInfo, {
            headers: {
                'Authorization': `Bearer ${token || this.token}`,
                'X-Requested-By': requestedBy,
                'X-Source': source
            }
        });
    }
    static async deleteUser(requestedBy, _id, source, token) {
        if (!token && (!this.token || this.expiresAt < new Date().getTime())) {
            await this.getToken();
        }
        return axios.delete(`${this.apiUrl}/v2/users/${_id}`, {
            headers: {
                'Authorization': `Bearer ${token || this.token}`,
                'X-Requested-By': requestedBy,
                'X-Source': source
            }
        });
    }
    static async refreshTap(requestedBy, email, source, token) {
        if (!token && (!this.token || this.expiresAt < new Date().getTime())) {
            await this.getToken();
        }
        return axios.put(`${this.apiUrl}/v2/users/refreshTap`, { email }, {
            headers: {
                'Authorization': `Bearer ${token || this.token}`,
                'X-Requested-By': requestedBy,
                'X-Source': source
            }
        });
    }
}

import React, { useState } from 'react';
import { CxLinks, AxLinks } from '../../types/app-links';
import { Combobox } from '@watts/design-system-react';
import { AreaOptions } from '../../types/areas';
import { EnvironmentOptions } from '../../types/environments';
import { Table } from '@watts/design-system-react';
export default function LinksTable() {
    const [areaSelected, setAreaSelected] = useState('');
    const [envSelected, setEnvSelected] = useState('');
    const handleValueChange = (name, value) => {
        if (name === 'area') {
            setAreaSelected(value);
        }
        else if (name === 'environment') {
            setEnvSelected(value);
        }
        else {
            throw new Error('Not a valid option');
        }
    };
    const styles = {
        comboboxContainer: {
            height: '100%',
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 1fr)',
            gridGap: '1em',
            gap: '10px',
            paddingBottom: '20px',
        },
    };
    const getFilteredLinks = () => {
        if (areaSelected === 'CX') {
            return CxLinks.has(envSelected) ? CxLinks.get(envSelected) : new Map();
        }
        else if (areaSelected === 'AX') {
            return AxLinks.has(envSelected) ? AxLinks.get(envSelected) : new Map();
        }
        return new Map();
    };
    const filteredLinks = getFilteredLinks();
    const tableProps = {
        caption: 'Links Table',
        className: 'custom-table',
        columns: [
            { name: 'Opco/Region' },
            { name: 'URL' },
        ],
        rows: Array.from(filteredLinks.entries()).map(([opco, url]) => ({
            cells: [
                { content: opco },
                { content: React.createElement("a", { href: url, target: "_blank", rel: "noopener noreferrer" }, url) },
            ],
        })),
    };
    return (React.createElement("div", null,
        React.createElement("div", { style: styles.comboboxContainer },
            React.createElement(Combobox, { label: 'Select the area', options: AreaOptions, defaultSelectedOption: AreaOptions[0], onValueChange: (value) => handleValueChange('area', value), "data-testid": 'area-select', noMatchLabel: '' }),
            React.createElement(Combobox, { label: 'Select the environment', options: EnvironmentOptions, defaultSelectedOption: EnvironmentOptions[0], onValueChange: (value) => handleValueChange('environment', value), "data-testid": 'environment-select', noMatchLabel: '' })),
        filteredLinks.size > 0 ? (React.createElement(Table, { ...tableProps })) : (React.createElement("p", null, "No links available for the selected environment."))));
}

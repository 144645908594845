const { Configuration } = require('@azure/msal-browser');
const redirectUri = process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : 'https://qa-ms.spark.sonepar.com';
// const redirectUri = 'http://localhost:3000';   // uncomment to test building for production
export const msalConfig = {
    auth: {
        clientId: `${process.env.MSAL_CLIENT_ID}`,
        authority: `https://login.microsoftonline.com/${process.env.MSAL_TENANT_ID}`,
        redirectUri: redirectUri
    },
};
export const loginRequest = {
    scopes: [`${process.env.MSAL_CLIENT_ID}/.default`]
};

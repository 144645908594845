export const Environments = [
    '',
    'Canary',
    'Feat',
    'Sint',
    'Preprod',
    'Prod'
];
export const EnvironmentOptions = Environments.map(env => {
    return { value: env, label: env };
});

import React, { createContext, useContext, useState } from 'react';
import { UserTypeOptions } from './types/user-types';
const AppStateContext = createContext(undefined);
export const AppStateProvider = ({ children }) => {
    const [appState, setAppState] = useState({
        currentPage: '',
        previousPage: '',
        query: {
            area: '',
            region: '',
            env: '',
            opco: '',
            label: '',
            accountId: '',
            ownerTeam: '',
            email: '',
            description: '',
            userType: UserTypeOptions[2].value,
        }
    });
    React.useEffect(() => {
        setAppState(prevState => ({
            ...prevState,
            previousPage: prevState.currentPage,
            currentPage: window.location.pathname,
            query: prevState.query
        }));
    }, []);
    return (React.createElement(AppStateContext.Provider, { value: { appState, setAppState } }, children));
};
export const useAppState = () => {
    const context = useContext(AppStateContext);
    if (!context) {
        throw new Error('useAppState must be used within an AppStateProvider');
    }
    return context;
};

import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import isEqual from 'lodash/isEqual';
import CopyToClipboard from '../copy-to-clipboard/copy-to-clipboard';
import ShowText from '../show-text/show-text';
import ExpandedUserDetails from '../expanded-user-details/expanded-user-details';
import { Button, DialogBox, Divider, Text, Tooltip, useToast } from '@watts/design-system-react';
import { useNavigate } from 'react-router-dom';
import { httpClient } from '../../httpClient';
import { useUser } from '../../user-context';
import { useAuth } from '../../auth-provider';
export default function UserResults({ data }) {
    const { accessToken, refreshToken } = useAuth();
    const navigate = useNavigate();
    const { sendToast } = useToast();
    const { requestedBy } = useUser();
    const [deleteDialogDisplayed, setDeleteDialogDisplayed] = useState(false);
    const [securityDialogDisplayed, setSecurityDialogDisplayed] = useState(false);
    const [entries, setEntries] = useState(data);
    const [selectedRow, setSelectedRow] = useState(null);
    const [tap, setTap] = useState('');
    const [copiedTap, setCopiedTap] = useState(false);
    const [copiedPassword, setCopiedPassword] = useState(false);
    const [copiedEmail, setCopiedEmail] = useState(false);
    const [loadingTap, setLoadingTap] = useState(false);
    useEffect(() => {
        if (!isEqual(entries, data)) {
            setEntries(data);
        }
    }, [data]);
    const handleDelete = async () => {
        setDeleteDialogDisplayed(false);
        await refreshToken();
        const res = await httpClient.deleteUser(requestedBy, selectedRow._id, 'frontend', accessToken);
        if (res.status === 201 || res.status === 200) {
            await sendToast({
                title: 'Success',
                message: 'User successfully deleted',
                status: 'success',
                closeLabel: '',
                duration: 'short',
            });
        }
        else {
            await sendToast({
                title: 'Error',
                message: 'An error occurred while deleting the user',
                status: 'error',
                closeLabel: '',
                duration: 'short',
            });
        }
    };
    const handleRefreshTap = async () => {
        setLoadingTap(true);
        await refreshToken();
        const user = await httpClient.refreshTap(requestedBy, selectedRow.email, 'frontend');
        setTap(user.data.tap);
        setLoadingTap(false);
    };
    const handleCopyToClipboard = async (content, text) => {
        await navigator.clipboard.writeText(text);
        switch (content) {
            case 'tap':
                setCopiedTap(true);
                setTimeout(() => {
                    setCopiedTap(false);
                }, 1000);
                break;
            case 'password':
                setCopiedPassword(true);
                setTimeout(() => {
                    setCopiedPassword(false);
                }, 1000);
                break;
            case 'email':
                setCopiedEmail(true);
                setTimeout(() => {
                    setCopiedEmail(false);
                }, 1000);
                break;
            default:
                console.log('Invalid value');
        }
    };
    const [columns, setColumns] = useState([
        {
            name: 'Email',
            cell: (row) => (React.createElement("div", { style: { flexDirection: 'row', display: 'flex', maxHeight: '40px' } },
                React.createElement("p", null, row.email),
                React.createElement(CopyToClipboard, { text: row.email }),
                React.createElement(Button, { variant: 'ghost', icon: 'pencil', onClick: () => {
                        navigate('/edit-user', { state: { initialUserInfo: row } });
                    } }),
                React.createElement(Button, { variant: 'ghost', icon: 'trash', onClick: () => {
                        setSelectedRow(row);
                        setDeleteDialogDisplayed(true);
                    } }))),
            sortable: true,
        },
        {
            name: 'Password',
            cell: (row) => (React.createElement("div", { style: { flexDirection: 'row', display: 'flex', maxHeight: '40px', justifyContent: 'flex-end' } },
                React.createElement(ShowText, { text: row.password }),
                React.createElement(CopyToClipboard, { text: row.password }),
                row.tapEnabled &&
                    React.createElement(Tooltip, { label: 'Show OTP', style: { zIndex: 1000 } },
                        React.createElement(Button, { icon: 'padlock-unlocked', variant: 'ghost', onClick: () => {
                                setSelectedRow(row);
                                setSecurityDialogDisplayed(true);
                            } })))),
            sortable: false,
            width: '200px',
        },
        {
            name: 'Label',
            selector: (row) => row.label || '',
            sortable: true,
        },
        {
            name: 'Owner team',
            selector: (row) => row.ownerTeam || '',
            sortable: true,
            width: '200px',
        },
        {
            name: 'Account ID',
            selector: (row) => row.accountId || '',
            sortable: true,
            width: '200px',
        },
        {
            name: 'Env',
            selector: (row) => row.envName || '',
            sortable: true,
            width: '100px',
        },
        {
            name: 'Opco',
            selector: (row) => row.opco || '',
            sortable: true,
            width: '150px',
        },
    ]);
    const customStyles = {
        table: {
            style: {
                borderRadius: '8px',
                overflow: 'hidden',
            },
        },
        headCells: {
            style: {
                backgroundColor: '#000000',
                color: '#ffffff',
                fontSize: '16px',
            },
        },
        rows: {
            style: {
                '&:nth-of-type(odd)': {
                    backgroundColor: '#f2f2f2',
                },
                '&:hover': {
                    backgroundColor: '#dae5f4',
                },
            },
        },
        cells: {
            style: {
                fontSize: '14px',
                paddingLeft: '10px',
                paddingRight: '10px',
                overflow: 'hidden',
            },
        },
        pagination: {
            style: {
                backgroundColor: '#f8f9fa',
                minHeight: '56px',
            },
            pageButtonsStyle: {
                borderRadius: '5px',
                height: '40px',
                width: '40px',
                padding: '0',
                margin: '0px 4px',
                cursor: 'pointer',
                transition: 'background-color 0.3s',
                backgroundColor: '#000000',
                fill: '#ffffff',
                '&:disabled': {
                    cursor: 'not-allowed',
                    backgroundColor: '#cccccc',
                },
                '&:hover:not(:disabled)': {
                    backgroundColor: '#ffffff',
                },
            },
        },
    };
    const styles = {
        table: {
            borderRadius: '8px',
            overflow: 'hidden',
        },
        headCells: {
            backgroundColor: '#007bff',
            color: '#ffffff',
            fontSize: '16px',
        },
        rowsOdd: {
            backgroundColor: '#f2f2f2',
        },
        rowsHover: {
            backgroundColor: '#dae5f4',
        },
        cells: {
            fontSize: '14px',
            paddingLeft: '10px',
            paddingRight: '10px',
        },
        pagination: {
            backgroundColor: '#f8f9fa',
            minHeight: '56px',
        },
        pageButtons: {
            borderRadius: '5px',
            height: '40px',
            width: '40px',
            padding: '0',
            margin: '0px 4px',
            cursor: 'pointer',
            transition: 'background-color 0.3s',
            backgroundColor: '#007bff',
            color: '#ffffff',
        },
        pageButtonsDisabled: {
            cursor: 'not-allowed',
            backgroundColor: '#cccccc',
        },
        pageButtonsHover: {
            backgroundColor: '#0056b3',
        },
        dialogContainer: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            gap: '10px',
            minWidth: '300px',
        },
        spacedElements: {
            display: 'flex',
            flexDirection: 'row',
            gap: '10px',
        },
        spacedElementsChild: {
            flex: 1,
        },
    };
    return (React.createElement("div", { "data-testid": 'userResults' },
        React.createElement(DataTable, { data: entries, columns: columns, pagination: true, expandableRows: true, expandableRowsComponent: ExpandedUserDetails, customStyles: customStyles }),
        React.createElement(DialogBox, { closeLabel: 'Close', "data-testid": 'delete-user-dialog-box', onClose: () => setDeleteDialogDisplayed(false), open: deleteDialogDisplayed },
            React.createElement("div", null,
                React.createElement(Text, { as: "span", color: "subtle", size: "body-3", weight: "bold" }, "Are you sure you want to delete this user?"),
                React.createElement("br", null),
                React.createElement("br", null),
                React.createElement("div", null,
                    React.createElement(Button, { variant: 'primary', size: 's', onClick: handleDelete }, "Confirm"),
                    React.createElement(Button, { variant: 'secondary', size: 's', onClick: () => {
                            setDeleteDialogDisplayed(false);
                        } }, "Cancel")))),
        React.createElement(DialogBox, { closeLabel: 'Close', "data-testid": 'otp-dialog-box', onClose: () => setSecurityDialogDisplayed(false), open: securityDialogDisplayed, style: { width: 'max-content' } },
            React.createElement("div", { style: styles.dialogContainer },
                React.createElement(Text, { as: "span", size: "body-5", weight: "bold", style: { textWrap: 'nowrap' } }, "Security info"),
                React.createElement(Text, { as: "span", size: "body-3", weight: "regular", style: { textWrap: 'nowrap' } }, selectedRow?.email),
                React.createElement(Divider, null),
                React.createElement(Text, null, "This user has Temporary Access Password enabled. Please use the TAP to log in."),
                React.createElement("br", null),
                React.createElement("br", null),
                React.createElement("div", { style: styles.spacedElements },
                    React.createElement(Button, { variant: 'primary', size: 's', onClick: () => handleCopyToClipboard('email', selectedRow.email) }, copiedEmail ? 'Copied!' : 'Copy email'),
                    selectedRow && !selectedRow.tapEnabled &&
                        React.createElement(Button, { variant: 'primary', size: 's', onClick: () => handleCopyToClipboard('password', selectedRow.password) }, copiedPassword ? 'Copied!' : 'Copy password'),
                    React.createElement(Button, { variant: 'primary', size: 's', onClick: () => handleCopyToClipboard('tap', tap || selectedRow.tap) }, copiedTap ? 'Copied!' : 'Copy TAP'),
                    React.createElement(Button, { variant: 'secondary', size: 's', onClick: handleRefreshTap }, loadingTap ? '🧐Please wait...' : '⚠️Force Refresh TAP'))))));
}

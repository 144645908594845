import * as React from 'react';
import { useLocation } from 'react-router-dom';
import UserForm from '../user-form/user-form';
import { useNavigate } from 'react-router-dom';
import { useAppState } from '../../app-state-context';
export default function AddUser() {
    const { state } = useLocation();
    const initialUserInfo = state?.initialUserInfo;
    const navigate = useNavigate();
    const { appState, setAppState } = useAppState();
    React.useEffect(() => {
        setAppState(prevState => {
            return {
                ...prevState,
                previousPage: prevState.currentPage,
                currentPage: '/add-user',
                query: prevState.query
            };
        });
    }, []);
    return (React.createElement("div", { "data-testid": 'addUser' },
        React.createElement(UserForm, { initialUserInfo: initialUserInfo, onSubmitSuccess: () => {
                navigate('/search');
            } })));
}

export const Opcos = [
    '',
    'AUT-ROUTECO',
    'AUTH_POC',
    'BEL-CEBEO',
    'BEL-ROUTECO',
    'COMMON',
    'FIN-SONFIN',
    'FRA-CONNECT',
    'GBR-ROUTECO',
    'HUN-SONHUN',
    'ITA-ELETTRO',
    'ITA-HDUEA',
    'ITA-SACCHI',
    'ITA-SONITA',
    'NLD-ROUTECO',
    'NLD-TECUNIE',
    'NOR-SONNOR',
    'USA-SPRFLD',
    'USA-CRAWFRD'
];
export const OpcoOptions = Opcos.map(opco => {
    return { value: opco, label: opco };
});

import React, { createContext, useContext, useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { loginRequest } from './authConfig';
const AuthContext = createContext(undefined);
export const AuthProvider = ({ children }) => {
    const { instance, accounts } = useMsal();
    const [accessToken, setAccessToken] = useState(null);
    const acquireToken = async (account) => {
        const request = {
            scopes: loginRequest.scopes,
            account: account,
        };
        try {
            const response = await instance.acquireTokenSilent(request);
            setAccessToken(response.accessToken);
        }
        catch (error) {
            console.error('Token acquisition failed:', error);
            if (error instanceof InteractionRequiredAuthError) {
                await instance.acquireTokenRedirect(request);
            }
        }
    };
    const refreshToken = async () => {
        if (accounts.length > 0) {
            await acquireToken(accounts[0]);
        }
    };
    useEffect(() => {
        if (accounts.length > 0) {
            acquireToken(accounts[0]);
        }
    }, [accounts, instance]);
    return (React.createElement(AuthContext.Provider, { value: { accessToken, refreshToken } }, children));
};
export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

import * as React from 'react';
import { useLocation } from 'react-router-dom';
import UserForm from '../user-form/user-form';
import { useAppState } from '../../app-state-context';
export default function EditUser() {
    const { state } = useLocation();
    const initialUserInfo = state?.initialUserInfo;
    const { appState, setAppState } = useAppState();
    React.useEffect(() => {
        setAppState(prevState => {
            return {
                ...prevState,
                previousPage: prevState.currentPage,
                currentPage: '/edit-user',
                query: prevState.query
            };
        });
    }, []);
    return (React.createElement("div", { "data-testid": 'editUser' },
        React.createElement(UserForm, { initialUserInfo: initialUserInfo, onSubmitSuccess: () => {
                // Handle success, e.g., navigate to another page or show a message
            } })));
}

import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { msalInstance } from './msalInstance';
import { Routes, Route, useNavigate, Navigate, HashRouter } from 'react-router-dom';
import Logo from './public/logo.svg';
import Pages from './components/pages/pages';
import AddUser from './components/add-user/add-user';
import { loginRequest } from './authConfig';
import Search from './components/search/search';
import { WattsProvider } from '@watts/design-system-react';
const MsalProvider = React.lazy(() => import('@azure/msal-react').then(module => ({ default: module.MsalProvider })));
const MsalAuthenticationTemplate = React.lazy(() => import('@azure/msal-react').then(module => ({ default: module.MsalAuthenticationTemplate })));
import '@watts/css';
import EditUser from './components/edit-user/edit-user';
import { UserProvider } from './user-context';
import { InteractionRequiredAuthError, InteractionType } from '@azure/msal-browser';
import { AppStateProvider } from './app-state-context';
import Links from './components/links/links';
import { useMsal } from '@azure/msal-react';
import { useEffect } from 'react';
import { AuthProvider } from './auth-provider';
const container = document.getElementById('root'); // Get the root element.
const root = createRoot(container);
const styles = {
    mainContainer: {
        fontFamily: 'sans-serif',
        padding: '20px',
    },
    heading: {
        color: '#27348B',
        fontSize: '24px',
    },
};
function AppContent() {
    const navigate = useNavigate();
    const { instance, accounts } = useMsal();
    const [appState, setAppState] = React.useState({ currentPage: '', previousPage: '', query: {} });
    useEffect(() => {
        const acquireToken = async (account) => {
            const request = {
                scopes: loginRequest.scopes,
                account: account,
            };
            try {
                const response = await instance.acquireTokenSilent(request);
                const accessToken = response.accessToken;
                console.log("Access Token acquired:", accessToken);
                // Use the access token as needed
            }
            catch (error) {
                console.error("Token acquisition failed:", error);
                if (error instanceof InteractionRequiredAuthError) {
                    await instance.acquireTokenRedirect(request);
                }
            }
        };
        if (accounts.length > 0) {
            acquireToken(accounts[0]);
        }
    }, [accounts, instance]);
    function onChangeTab(pageType) {
        navigate(pageType);
    }
    return (React.createElement(MsalAuthenticationTemplate, { interactionType: InteractionType.Redirect, authenticationRequest: loginRequest },
        React.createElement("div", { style: styles.mainContainer },
            React.createElement("img", { src: Logo, alt: "logo", style: { maxHeight: '30px', cursor: 'pointer' }, onClick: () => navigate('/') }),
            React.createElement(Pages, { onChangeTab: onChangeTab }),
            React.createElement(Routes, null,
                React.createElement(Route, { path: "/", element: React.createElement(Navigate, { to: "/user-search" }) }),
                React.createElement(Route, { path: "/user-search", element: React.createElement(Search, null) }),
                React.createElement(Route, { path: "/add-user", element: React.createElement(AddUser, null) }),
                React.createElement(Route, { path: "/edit-user", element: React.createElement(EditUser, null) }),
                React.createElement(Route, { path: "/links", element: React.createElement(Links, null) })))));
}
export default function App() {
    return (React.createElement(WattsProvider, null,
        React.createElement(MsalProvider, { instance: msalInstance },
            React.createElement(UserProvider, null,
                React.createElement(HashRouter, null,
                    React.createElement(AppStateProvider, null,
                        React.createElement(AuthProvider, null,
                            React.createElement(AppContent, null))))))));
}
root.render(React.createElement(App, null));
